import React from 'react';
import './Functions.scss';
import { WithTranslation, withTranslation } from "react-i18next";

interface MyProps extends WithTranslation {
}

interface MyState {
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    jumpTo = (className: string) => {
        const yOffset = -60;
        const element = document.getElementsByClassName(className)[0];
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    render() {
        const { t } = this.props;
        return (
            <div className='functions-wrapper'>
                <div className='header-main'>
                    <div className='header-title'>
                        <h2>Şəfa Kartım</h2>
                        <p>{t("What can you do with Şəfa Kartım")}</p>
                    </div>

                    <div className='header-features'>
                        <div onClick={() => this.jumpTo('doctor-access')} className='features-item'>
                            <div className='img-block'>
                                <img width={45} src={require('./../../assets/images/medical-card-items/doctor-access.png').default} alt={'Doctor access icon'} />
                            </div>
                            <p>{t("Access for doctors")}</p>
                        </div>

                        <div onClick={() => this.jumpTo('medical-card')} className='features-item'>
                            <div className='img-block'>
                                <img width={55} src={require('./../../assets/images/blue-icons/medical-card.png').default} alt={'Medical card icon'} />
                            </div>
                            <p>{t("My medical record")}</p>
                        </div>

                        <div onClick={() => this.jumpTo('favorite')} className='features-item'>
                            <div className='img-block'>
                                <img width={55} src={require('./../../assets/images/blue-icons/favorite.png').default} alt={'Favorite icon'} />
                            </div>
                            <p>{t("Preferences")}</p>
                        </div>

                        <div onClick={() => this.jumpTo('bank-card')} className='features-item'>
                            <div className='img-block'>
                                <img width={55} src={require('./../../assets/images/blue-icons/bank-card.png').default} alt={'Bank card icon'} />
                            </div>
                            <p>{t("Payment and insurance")}</p>
                        </div>

                        <div className='mob-separator'></div>

                        <div onClick={() => this.jumpTo('calendar')} className='features-item'>
                            <div className='img-block'>
                                <img width={57} src={require('./../../assets/images/blue-icons/calendar.png').default} alt={'Calendar icon'} />
                            </div>
                            <p>{t("Schedule")}</p>
                        </div>

                        <div onClick={() => this.jumpTo('mobile')} className='features-item'>
                            <div className='img-block'>
                                <img width={35} src={require('./../../assets/images/blue-icons/mobile.png').default} alt={'Mobile icon'} />
                            </div>
                            <p>{t("Mobile features")}</p>
                        </div>

                        <div onClick={() => this.jumpTo('video')} className='features-item'>
                            <div className='img-block'>
                                <img width={65} src={require('./../../assets/images/blue-icons/video.png').default} alt={'Video icon'} />
                            </div>
                            <p>{t("Video Call")}</p>
                        </div>

                        <div onClick={() => this.jumpTo('message')} className='features-item'>
                            <div className='img-block'>
                                <img width={52} src={require('./../../assets/images/blue-icons/message.png').default} alt={'Message icon'} />
                            </div>
                            <p>{t("Messaging")}</p>
                        </div>
                    </div>
                </div>

                <div className='items-desc-block'>
                    <div className='item doctor-access'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={45} src={require('./../../assets/images/medical-card-items/doctor-access.png').default} alt={'Doctor access icon'} />
                                </div>
                                <p>{t("Access for doctors")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Daily health indicators")}</h5>
                            <p>
                                {t("To coordinate and monitor your health and treatment, including the regulation of drug dosages, you can provide temporary access to your doctors to such daily health indicators as blood pressure, pulse, blood oxygen saturation, sugar on an empty stomach, etc.")}
                            </p>
                            <h5>{t("Summary of health status and visits")}</h5>
                            <p>
                                {t("Share your health information and medical documentation with your doctor in advance to get more complete and comprehensive medical care.")}
                            </p>
                            <h5>{t("Quick access for new and on-call doctors")}</h5>
                            <p>
                                {t("Regardless of your location - whether you are in Baku or in some remote area of the country, whether you are in Azerbaijan or in another country in the world-share quick, temporary access to your medical records and information about your health with the clinic and doctor to receive full and high-quality medical care.")}
                            </p>
                            <h5>{t("Who got access to my account?")}</h5>
                            <p>{t("Find out which of family members and doctors had access to your medical records.")}</p>
                            <h5>{t("Share my post")}</h5>
                            <p>
                                {t("With Şəfa Kartım, give any provider with a computer and Internet access a temporary one-time access to your medical record.")}
                            </p>
                        </div>
                    </div>

                    <div className='item medical-card'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={55} src={require('./../../assets/images/blue-icons/medical-card.png').default} alt={'Medical card icon'} />
                                </div>
                                <p>{t("My medical record")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Test results")}</h5>
                            <p>
                                {t("Get timely notification of the results of completed tests and view the results of past tests.")}
                            </p>
                            <h5>{t("Medicines")}</h5>
                            <p>
                                {t("Review your medication list and instructions for taking each medication and report the medications you are no longer taking.")}
                            </p>
                            <h5>{t("Allergies")}</h5>
                            <p>{t("Review your allergies and update them as needed.")}</p>
                            <h5>{t("Current health problems")}</h5>
                            <p>{t("Review your current health issues and update them as needed.")}</p>
                            <h5>{t("Immunization")}</h5>
                            <p>
                                {t("Review your vaccination history and document the vaccinations you received elsewhere, such as at a flu vaccination clinic.")}
                            </p>
                            <h5>{t("Medical history")}</h5>
                            <p>{t("Review and record your personal and family medical history.")}</p>
                            <h5>{t("Health trends")}</h5>
                            <p>
                                {t("Graph of vital signs and laboratory results over time.")}
                            </p>
                            <h5>{t("My documents")}</h5>
                            <p>
                                {t("View all documents stored in your healthcare organization in one place, such as post-visit resumes, signed medical notes, message attachments, and health care planning documents.")}
                            </p>
                            <h5>{t("Search in the Health library")}</h5>
                            <p>{t("Find articles about symptoms, procedures, and other medical topics.")}</p>
                        </div>
                    </div>

                    <div className='item message'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={52} src={require('./../../assets/images/blue-icons/message.png').default} alt={'Message icon'} />
                                </div>
                                <p>{t("Messaging")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Message Center")}</h5>
                            <p>
                                {t("View the messages you have received or sent to your health care provider, including any attachments.")}
                            </p>
                            <h5>{t("Get medical advice")}</h5>
                            <p>{t("Send a message to ask your doctor to consult your doctor and get recommendations for care.")}</p>
                            <h5>{t("Ask Customer Support")}</h5>
                            <p>{t("Send non-medical messages to customer support or billing staff.")}</p>
                            <h5>{t("Your recipes")}</h5>
                            <p>
                                {t("Ask your doctor to renew or repeat your prescription or prescribe you a new medication and get a notification from the pharmacy about when you can pick up your medication.")}
                            </p>
                        </div>
                    </div>

                    <div className='item video'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={65} src={require('./../../assets/images/blue-icons/video.png').default} alt={'Video icon'} />
                                </div>
                                <p>{t("Video Call")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Live video chat with your doctor")}</h5>
                            <p>
                                {t("Make an appointment and get a doctor's consultation by video from the comfort of your home or while you at work or on a trip abroad.")}
                            </p>
                            <h5>{t("Clinic and hospital near you")}</h5>
                            <p>{t("If you need an urgent face-to-face consultation with a doctor, find medical facilities near you.")}</p>
                            <h5>{t("Doctor on duty")}</h5>
                            <p>
                                {t("Using the doctor on duty function, you can get an in-person or video consultation with a doctor without an appointment.")}
                            </p>
                            <h5>{t("Consilium")}</h5>
                            <p>
                                {t("Using the Consult with a Colleague function, your doctor will be able to discuss your medical history with other specialists.")}
                            </p>

                            <h5>{t("Send messages while on a video call")}</h5>
                            <p>
                                {t("While on a video call, send and receive messages, photos, and scanned documents to your doctor.")}
                            </p>
                        </div>
                    </div>

                    <div className='item calendar'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={57} src={require('./../../assets/images/blue-icons/calendar.png').default} alt={'Calendar icon'} />
                                </div>
                                <p>{t("Schedule")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Summary of the visit")}</h5>
                            <p>{t("View information about your past visits or hospital stays.")}</p>
                            <h5>{t("See Clinical Notes")}</h5>
                            <p>{t("Review the notes that your doctor has written down and shared with you.")}</p>
                            <h5>{t("Make an appointment")}</h5>
                            <p>
                                {t("Make an appointment yourself or ask the clinic to contact you. View information about future appointments, including instructions and directions, or cancel appointments that you no longer need.")}
                            </p>
                            <h5>eCheck-In</h5>
                            <p>
                                {t("Complete the check-in process from home to save time on arrival at the clinic. Pay surcharges for visits, check your medical and insurance information, answer questionnaires, and sign documents electronically.")}
                            </p>
                        </div>
                    </div>

                    <div className='item bank-card'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={57} src={require('./../../assets/images/blue-icons/bank-card.png').default} alt={'Bank card icon'} />
                                </div>
                                <p>{t("Payment and insurance")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Billing Account Summary")}</h5>
                            <p>
                                {t("View summary information about your clinic's and hospital's billing accounts, including outstanding balance, account details, accruals, and recent payments.")}
                            </p>
                            <h5>{t("Paperless billing")}</h5>
                            <p>{t("Sign up for a paperless account to receive medical bills via Şəfa Kartım, rather than by mail.")}</p>
                            <h5>{t("Payment of invoices")}</h5>
                            <p>
                                {t("View your account statements and make online payments on your medical bills, or make surcharges through your bank account or credit card. Pay the bill without entering Şəfa Kartım, for example, if you are receiving treatment at another medical organization while traveling or you need to make a payment on behalf of another person.")}
                            </p>
                        </div>
                    </div>

                    <div className='item favorite'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={55} src={require('./../../assets/images/blue-icons/favorite.png').default} alt={'Favorite icon'} />
                                </div>
                                <p>{t("Preferences")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Personal information")}</h5>
                            <p>
                                {t("Keep your contact information up-to-date for yourself and for emergency contacts, and give your health care providers more information about you, including your preferred language, preferred name, gender identity, and other personal information")}
                            </p>
                            <h5>{t("Restoring your username and password")}</h5>
                            <p>
                                {t("Set up security features that will help you log in if you forget your username or password Şəfa Kartım.")}
                            </p>
                            <h5>{t("Two-factor authentication")}</h5>
                            <p>
                                {t("Add extra security to your account by requiring you to enter a numeric code sent by text message or email every time you log in to Şəfa Kartım.")}
                            </p>
                            <h5>{t("Notifications")}</h5>
                            <p>
                                {t("Write down your preferences to receive notifications about new information in Şəfa Kartım, such as messages and test results, via email, text messages, or push notifications.")}
                            </p>
                            <h5>{t("Account management and linking")}</h5>
                            <p>
                                {t("Allow Şəfa Kartım to connect to another application and share your data, as well as manage connections to accounts in other medical organizations.")}
                            </p>
                            <h5>{t("Multilingual Şəfa Kartım")}</h5>
                            <p>{t("View Şəfa Kartım in another language.")}</p>
                        </div>
                    </div>

                    <div className='item mobile'>
                        <div className='left'>
                            <div className='img-block-wrapper'>
                                <div className='img-block'>
                                    <img width={35} src={require('./../../assets/images/blue-icons/mobile.png').default} alt={'Mobile icon'} />
                                </div>
                                <p>{t("Mobile features")}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>{t("Alternative login methods")}</h5>
                            <p>
                                {t("Quickly log in to the Şəfa Kartım mobile app using your fingerprint, face recognition, or a four-digit password.")}
                            </p>
                            <h5>Apple Watch</h5>
                            <p>
                                {t("Connect the Şəfa Kartım mobile app to your Apple Watch to receive notifications, view upcoming appointments, read messages, and view medications.")}
                            </p>
                            <h5>{t("Şəfa Kartım on the bedside table")}</h5>
                            <p>
                                {t("Stay informed and participate in your treatment during your hospital stay. Use Şəfa Kartım to keep track of your daily routine, learn more about your medical team members and send them messages when needed, make non-urgent requests, track your latest vital signs and lab tests, view your clinical notes, and view training materials.")}
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export const FunctionsComponent = withTranslation()(Proto);