import React from 'react';
import { Link } from 'react-router-dom';
import './About.scss';
import { WithTranslation, withTranslation } from "react-i18next";

// svg
import VideodoktorLogoSVG from './../../assets/svg/videodoktor-logo.svg';
import HealthCardLogoSVG from './../../assets/svg/health-card-logo.svg';

interface MyProps extends WithTranslation {
}

interface MyState {
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    render() {

        const { t } = this.props;

        return (
            <div className='about-wrapper'>

                <div className='header-main'>
                    <h1>{t("«Şəfa Kartım» by «VideoDoktor»")}</h1>
                    <h2>{t("With a deep sense of medical duty")}</h2>
                </div>

                <div className='what-is-shefa-kartim'>
                    <div className='left'>
                        <h2>{t("What is Şəfa Kartım?")}</h2>
                        <p>
                            {t("Şəfa Kartım gives you access to your medical information and a direct link to your care team, with tools to help you actively participate in your treatment.")}
                        </p>
                    </div>
                    <div className='right'>
                        <img width={450} src={require('./../../assets/images/what-is-shefa-kartim.png').default} alt={'What is shefa kartim'} />
                    </div>
                </div>

                <div className='why'>
                    <div className='main-description'>
                        <h2>{t("Why are we developing electronic medical resources?")}</h2>
                        <p>
                            {t("Created in 2020 by Ezgil Medical Technologies, the innovative VideoDoktor program aims to improve the quality and coverage of medical care for the population, expand the capabilities of the healthcare system and bring the interaction of the doctor with the patient to a completely new level, ensuring a more complete participation of the patient in the treatment and diagnostic process.")}
                        </p>
                    </div>

                    <div className='items-block'>

                        <div className='item'>
                            <Link to={{ pathname: 'https://videodoktor.az' }} target='_blank' className='link-block'>
                                <img width={75} src={VideodoktorLogoSVG} alt={'Videodoktor logo'} />
                                <h3>VideoDoktor</h3>
                            </Link>
                            <p>
                                {t("Developed by EZGIL Medical Technologies innovative program 'VideoDoctor' helps people restore and maintain their health, and takes patient-physician interaction to a whole new level. Today, hundreds of patients and doctors from various cities and regions of Azerbaijan have been registered in VideoDoctor.")}
                            </p>
                        </div>

                        <div className='item'>
                            <Link to={'/'} className='link-block'>
                                <img width={75} src={HealthCardLogoSVG} alt={'Health Card Logo'} />
                                <h3>Şəfa Kartım</h3>
                            </Link>
                            <p>
                                {t("We provide patients with even more information autonomy, access to information about their health and medical records, and contributes to the development of electronic services in the country in the healthcare sector.")}
                            </p>
                        </div>

                        <div className='item'>
                            <Link to={'/'} className='link-block' style={{ pointerEvents: 'none' }}>
                                <img width={100} src={require('./../../assets/images/saglamligim.png').default} alt={'Saglamligim Logo'} />
                            </Link>
                            <p>
                                {t("With the help of the mobile app 'Saglamligim', we provide patients with access to reliable information and focused recommendations from leading experts in the field of healthy lifestyle, prevention of infectious and non-communicable diseases, including diabetes, heart attack and stroke, obesity, asthma, etc.")}
                            </p>
                        </div>

                        <div className='item'>
                            <Link to={{ pathname: 'http://burlaxatun.az/' }} target='_blank' className='link-block'>
                                <img width={190} src={require('./../../assets/images/burla-xatun.png').default} alt={'Burla xatun Logo'} />
                            </Link>
                            <p>
                                {t("Information and valuable recommendations about family planning, pregnancy and childbirth, as well as about the health of newborns and children in one mobile app 'Burla Khatun'.")}
                            </p>
                        </div>

                        <div className='item'>
                            <Link to={{ pathname: 'https://dermanlarim.az/' }} target='_blank' className='link-block'>
                                <img width={100} src={require('./../../assets/images/dermanlarim.png').default} alt={'Dermanlarim Logo'} />
                            </Link>
                            <p>
                                {t("Through the unique portal and the mobile application of the same name 'Dermanlarim', patients get access to detailed information about more than 4000 medications, including their side effects, contraindications, and administration during pregnancy and lactation.")}
                            </p>
                        </div>

                        <div className='item'>
                            <Link to={'/'} className='link-block' style={{ pointerEvents: 'none' }}>
                                <img width={180} src={require('./../../assets/images/e-resept.png').default} alt={'E-resept Logo'} />
                            </Link>
                            <p>
                                {t("Prescribing, renewing, and other changes in medications, coordinating prescriptions between doctors of various specialties, and ensuring electronic data transfer between the doctor, patient, and pharmacy through the innovative 'E-receive' platform.")}
                            </p>
                        </div>


                    </div>

                </div>

                <div className='what-people-say'>

                    <div className='mob-title'>
                        <h2>{t("What people say")}</h2>
                        <p>{t("Here are the reviews that users leave")}:</p>
                    </div>

                    <div className='left-main'>
                        <div className='item-main'>
                            <div className='image-block'>
                                <img width={230} src={require('./../../assets/images/young-man-with-beard.png').default} alt={'Young man with beard'} />
                            </div>
                            <div className='desc-block'>
                                <p>
                                    {t("'After visiting so many doctors, it's nice to see all my information in one place.'")}
                                </p>
                            </div>
                        </div>

                        <div className='item-main'>
                            <div className='image-block'>
                                <img width={230} src={require('./../../assets/images/old-man.png').default} alt={'Old man'} />
                            </div>
                            <div className='desc-block'>
                                <p>
                                    {t("'I'm really starting to get my diabetes under control - I feel like I have everything under control.'")}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='middle-main'>
                        <div className='item-main-title'>
                            <h2>{t("What people say")}</h2>
                            <p>{t("Here are the reviews that users leave")}:</p>
                        </div>

                        <div className='item-main'>
                            <div className='image-block'>
                                <img width={230} src={require('./../../assets/images/young-man-like.png').default} alt={'Young man like'} />
                            </div>
                            <div className='desc-block'>
                                <p>
                                    {t("'I like to have opportunity to ask the doctor question directly from my phone.'")}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='right-main'>
                        <div className='item-main'>
                            <div className='image-block'>
                                <img width={230} src={require('./../../assets/images/old-woman.png').default} alt={'Old woman'} />
                            </div>
                            <div className='desc-block'>
                                <p>
                                    {t("'During my recent stay in the hospital, it was much easier for me to have my information with me.'")}
                                </p>
                            </div>
                        </div>

                        <div className='item-main'>
                            <div className='image-block'>
                                <img width={230} src={require('./../../assets/images/happy-girl.png').default} alt={'Happy girl'} />
                            </div>
                            <div className='desc-block'>
                                <p>
                                    {t("Sharing all the information between family members really helps me stay up to date.")}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='separator-block'></div>

            </div >

        )
    }
}

export const AboutComponent = withTranslation()(Proto);