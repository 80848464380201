import React from 'react';
import ReactDOM from 'react-dom';
import './utils/index.scss';
import App from './App';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ScrollToTop from 'react-router-scroll-top'

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);