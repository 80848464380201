import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from './locales/en/translation.json';
import translationAz from './locales/az/translation.json';
import translationRu from './locales/ru/translation.json';

const lang = localStorage.getItem('shefa.kartim.lang');

i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEn
            },
            az: {
                translation: translationAz
            },
            ru: {
                translation: translationRu
            },
        },
        lng: lang ? lang : "ru",
        fallbackLng: "en",
        nsSeparator: ":::",
        keySeparator: "::",
        interpolation: {
            escapeValue: false,
        },
    });
