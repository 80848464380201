import React from 'react';
import { Link } from 'react-router-dom';
import './MedicalCard.scss';
import { WithTranslation, withTranslation } from "react-i18next";

// svg
import AddCircleSVG from './../../assets/svg/add-circle.svg';
import VideodoktorLogoSVG from './../../assets/svg/videodoktor-logo.svg';

interface MyProps extends WithTranslation {
}

interface MyState {
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { t } = this.props;
        const lang = this.props.i18n.language;

        return (
            <div className='medical-card-wrapper'>

                <div className='main-info-block'>
                    <h2>{t('All your health information and medical records in one place')}</h2>
                    <p>
                        {t('If you are seen in different medical institutions by several doctors, or have received medical care in other cities or countries, this means that you have several medical records, including in paper form. Şəfa Kartım allows you to collect all your medical records in one place, so that they are always close to you, wherever you are.')}
                    </p>
                    <p>{t("We call it 'Happy together.'")}</p>
                </div>

                <div className='medical-card-items-main'>
                    <h2>
                        {t('When you connect your accounts from participating organizations, information from these organizations will be displayed directly in your Şəfa Kartım, including')}:
                    </h2>

                    <div className='items-wrapper'>
                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={72} src={require('./../../assets/images/medical-card-items/allergies.png').default} alt={'Allergies'} />
                            </div>
                            <p>{t('My allergies')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={68} src={require('./../../assets/images/medical-card-items/surveys.png').default} alt={'Surveys'} />
                            </div>
                            <p>{t('My surveys')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={58} src={require('./../../assets/images/medical-card-items/medicines.png').default} alt={'Medicines'} />
                            </div>
                            <p>{t('My medications')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={62} src={require('./../../assets/images/medical-card-items/vaccinations.png').default} alt={'Vaccinations'} />
                            </div>
                            <p>{t('My vaccinations')}</p>
                        </Link>

                        <div className='separator'></div>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={47} src={require('./../../assets/images/medical-card-items/analyzes.png').default} alt={'Analyzes'} />
                            </div>
                            <p>{t('My analysis')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={65} src={require('./../../assets/images/medical-card-items/notes.png').default} alt={'Notes'} />
                            </div>
                            <p>{t('My notes')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={58} src={require('./../../assets/images/medical-card-items/consultations.png').default} alt={'Consultations'} />
                            </div>
                            <p>{t('My consultations')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={65} src={require('./../../assets/images/medical-card-items/indicators.png').default} alt={'Indicators'} />
                            </div>
                            <p>{t('My metrics')}</p>
                        </Link>

                        <Link to={'/'} className='item'>
                            <div className='img-block'>
                                <img width={48} src={require('./../../assets/images/medical-card-items/doctor-access.png').default} alt={'Doctor Acess'} />
                            </div>
                            <p>{t('Access for doctors')}</p>
                        </Link>
                    </div>

                </div>

                <div className='features-main'>
                    <h2>{t('You have access to')}</h2>

                    <div className='features-list-items'>
                        <div className='left-block'>
                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Allergies' section, you can add or correct information about drug allergies and mark an allergic reaction caused by taking a particular drug")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Surveys' section, you can add all the surveys that you have been assigned and record or attach the results of these surveys")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Medications' section, you can add, delete, or correct the list of medications you take with their dosages, frequency of administration, and duration of administration.")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Vaccinations' section, you can add information about your vaccinations and immunizations, noting the name of the vaccine and the date of injection.")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Analysis' section, you can add and view the results of your laboratory tests. You can also take a photo or scan the laboratory results you have received and attach them as files.")}
                                </p>
                            </div>
                        </div>

                        <div className='right-block'>
                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Notes' section, you will be able to add your notes, any additional information that may be useful for the medical professionals who provide you with medical care.")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Consultations' section, you can add information about the consultations you have received from doctors of general and narrow profiles, including the dates and clinics where you received these consultations, as well as mark their conclusions and recommendations.")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'My Indicators' section, you will be able to monitor the main indicators of your health, including daily notes of your blood pressure, pulse, temperature, blood oxygen saturation, sugar, cholesterol level in the blood, etc. You can also see changes in your metrics on visual charts and compare metrics over specific time periods.")}
                                </p>
                            </div>

                            <div>
                                <img width='19' src={AddCircleSVG} alt="Add Circle" />
                                <p>
                                    {t("In the 'Access for Doctors' section, you can provide your doctors with temporary short-term or long-term access to your medical records and dynamic health indicators, regardless of your location or time of day.")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='connect-main'>
                    <h2>{t('How to connect Şəfa Kartım accounts')}</h2>
                    <div className='blackbox'></div>
                </div>

                <div className='how-it-works-main'>
                    <div className='left'>
                        <h2>{t('Here is an example of how it works')}:</h2>
                        <p>{t('Abdul lived in the mountains, and then grew up, bought a house in the village and moved.')}</p>
                        <img width={400} src={require('./../../assets/images/guy-1.png').default} alt={'Guy 1'} />
                    </div>
                    <div className='right'>
                        <img width={400} src={require('./../../assets/images/guy-2.png').default} alt={'Guy 2'} />
                        <p>
                            {t('Abdul can link his medical record from his mountain clinic to his account Şəfa Kartım so that he could see his information in one place.')}
                        </p>
                    </div>
                </div>

                <div className='demonstation-main'>
                    <h2>
                        {t('With the help of Şəfa Kartım, you can access information about your health, medical records from various medical institutions, including the results of diagnostic tests, laboratory tests and prescriptions, etc.')}
                    </h2>
                    <p>
                        {t('View and update your medical records anytime, anywhere!')}
                    </p>
                    <img width={520} src={
                        lang === 'az' ?
                            require('./../../assets/images/demonstation-az.png').default
                            : lang === 'en' ?
                                require('./../../assets/images/demonstation-en.png').default
                                :
                                require('./../../assets/images/demonstation-ru.png').default
                    } alt={'Demonstation ru'} />
                </div>

                <div className='questions-main'>
                    <div className='questions-wrapper'>
                        <h2>{t('Do you have questions?')}</h2>

                        <h3>{t('What do I need to do to start using Happy Together?')}</h3>
                        <p>
                            {t('If your doctors use Happy Together, you will be asked if you want to link your Şəfa Kartım accounts the next time you log in to one of your Şəfa Kartım accounts.')}
                        </p>

                        <h3>{t("I don't see any of the medical organizations available for reference. How can I add this?")}</h3>
                        <p>
                            {t('Some organizations have not yet activated Happy Together. Visit www.videodoktor.az to find out which organizations are already using Happy Together and which will join soon.')}
                        </p>

                        <h3>{t("What should I do if I don't recognize the organization that is listed in my list of accounts?")}</h3>
                        <p>
                            {t("You will see the organizations where you have a medical card. Place your mouse over 'Why am I seeing this?' for more details about your last visit.")}
                        </p>
                        <p>
                            {t('Sometimes the name of a medical organization may seem familiar, because')}
                        </p>

                        <ul>
                            <li>
                                {t('The clinic where you were examined may use a name that differs from the name of the parent organization listed in Happy Together.')}
                            </li>
                            <li>{t('The clinic may be part of a larger healthcare network whose name is not used locally.')}</li>
                            <li>{t('An organization that you were seen in a long time ago may have changed its name from the last time you were there.')}</li>
                        </ul>

                        <h3>{t('How can I find out where my information came from?')}</h3>
                        <div className='last'>
                            <img width={13} src={VideodoktorLogoSVG} alt={'Videodoktor Logo'} />
                            <p>
                                : {t("This icon appears next to information from another medical organization to help you understand where the information is coming from. Place your mouse pointer over this icon (or, if you're using your phone, tap and hold) to see more detailed information.")}
                            </p>
                        </div>

                    </div>
                </div>

            </div >
        )
    }
}

export const MedicalCardComponent = withTranslation()(Proto);