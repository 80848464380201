import React from 'react';
// import { Link } from 'react-router-dom';
import './Share.scss';
import { WithTranslation, withTranslation } from "react-i18next";

// svg
import AddCircleSVG from './../../assets/svg/add-circle.svg';

interface MyProps extends WithTranslation {
}

interface MyState {
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { t } = this.props;
        const lang = this.props.i18n.language;

        return (
            <div className='share-wrapper'>

                <div className='main-info-block'>
                    <h2>{t('Sharing your medcard')}</h2>

                    <div className='content-blocks'>

                        <div className='left-block'>
                            <h3>
                                {t('The medical care provided to you will be more effective and complete if your medcard and information about your health is available to the medical institution and doctors')}
                            </h3>
                            <p>
                                {t("When health care providers take care of you or provide you with medical care, most of them can already access the medical information they need and receive automatic updates about the treatment you receive at this facility. However, they may not have access to information about your health in general and about the treatment received in other medical institutions. In such cases, you can share the necessary medical information with your doctors using the 'Share with the Doctor' function.")}
                            </p>
                        </div>

                        <div className='right-block'>
                            <img width={470} src={require('./../../assets/images/sharing.png').default} alt={'Sharing'} />
                        </div>

                    </div>
                </div>

                <div className='how-doctor-access-works'>
                    <div className='left'>
                        <div className='blackbox'></div>
                        <div className='mob-block'>
                            <p>
                                Ваши доктора, работающие в медучреждениях, использующих Şəfa Kartım могут также обмениваться информацией
                                о вашем лечении с вашими другими докторами для более эффективной координации вашего лечения и оказания вам
                                медпомощи. Благодаря автоматическому обновлению медицинских данных, все ваши доктора сразу получают доступ
                                к последним данным, в том числе результатам лабораторных анализов либо выписанным лекарствам.
                            </p>
                            <img width={430} src={require('./../../assets/images/doctor-examining-patient.png').default} alt={'Doctor examining patient'} />
                        </div>
                    </div>

                    <div className='right'>
                        <h2>
                            {t('How do doctors access my medical information?')}
                        </h2>
                        <h3>
                            {t("With the 'Share with Doctor' feature, your doctors get temporary short-term or long-term access to your medical records and health indicators. They can also update their records of your checkup and treatment, prescriptions issued, and diagnostic procedures assigned.")}
                        </h3>
                        <p>
                            {t('Your doctors working in medical institutions using Şəfa Kartım can also share information about your treatment with your other doctors to better coordinate your treatment and provide you with medical care. Thanks to the automatic updating of medical data, all your doctors immediately have access to the latest data, including the results of laboratory tests or prescribed medications.')}
                        </p>
                        <img width={430} src={require('./../../assets/images/doctor-examining-patient.png').default} alt={'Doctor examining patient'} />
                    </div>
                </div>

                <div className='how-it-works-1'>
                    <div className='left'>
                        <h2>
                            {t("Here is an example of how it works ...")}
                        </h2>
                        <h3>
                            {t("Maria was a patient in the children's Diseases department of a hospital in Sumgait when she was 10 years old. Due to moving to Baku, she had changed her doctor.")}
                        </h3>
                        <p>
                            {t("She has a complicated medical history, so she wants to make sure that her new doctor in Baku will have access to all the medical records, including epicrisis, out-patient records, and lab results from various clinics over the years. As soon as Maria registers in a new clinic (private or public), the Şəfa Kartım system automatically finds Maria's medical card with all the information from various medical institutions, including the hospital in Sumgait, and makes it available to her new institution and doctors.")}
                        </p>
                    </div>

                    <div className='right'>
                        <img width={420} src={require('./../../assets/images/example-how-it-works.png').default} alt={'Example how it works 1'} />
                    </div>
                </div>

                <div className='add-doctor-access'>
                    <div className='left'>
                        <h2>
                            {t("What should I do if my doctors don't have access to my medical records?")}
                        </h2>
                        <h3>
                            {t("The 'Share with a Doctor' feature allows you to provide medical workers who give you routine or emergency medical care with temporary access to your medical records and health indicators.")}
                        </h3>
                        <div className='blackbox mobile-block'></div>
                        <p>
                            {t("With the help of a temporary shared access code in Şəfa Kartım, you will be able to grant someone access to your medical record in any web browser. And if a new doctor or medical institution also uses the VideoDoktor or Şəfa Kartım system, then you can grant them access to your medical record by simply adding them to the list of your preferred clinics and doctors. Using access to your medical record, your doctor will be able to study your medical history, get acquainted with the results of previous tests or diagnostic studies, as well as with the treatment that you have received so far, and make your notes or additions, thereby making the information in your medical record relevant at the moment.")}
                        </p>
                    </div>

                    <div className='right'>
                        <div className='blackbox'></div>
                        <img width={460} src={require('./../../assets/images/provision-acess-for-doctors.png').default} alt={'Provision acess for doctors'} />
                    </div>
                </div>

                <div className='how-it-works-2'>
                    <div className='left'>
                        <div className='left-texts'>
                            <h2>{t('Here is an example of how it works ...')}</h2>
                            <h3>{t('Mamed goes to Spain for a vacation and sprains his ankle on a hike.')}</h3>
                            <p>
                                {t("Since he is outside of Baku and Azerbaijan, he does not have access to his doctor and the clinic where he usually receives medical care. He is forced to seek medical help in a clinic in Spain, where of course there is no access to his medical record and information about his health.")}
                            </p>
                        </div>
                        <img width={450} src={
                            lang === 'az' ?
                                require('./../../assets/images/doctor-acces-app-az.png').default
                                : lang === 'en' ?
                                    require('./../../assets/images/doctor-acces-app-en.png').default
                                    :
                                    require('./../../assets/images/doctor-acces-app-ru.png').default
                        } alt={'Doctor acces app'} />
                    </div>
                    <div className='right'>
                        <img width={450} src={require('./../../assets/images/guy-under-the-tree.png').default} alt={'Guy under the tree'} />
                        <div className='right-texts'>
                            <h2>
                                {t("Mamed can use the 'share with the doctor' function in the Videodoctor mobile app or through a web browser on his phone and send the code to the doctor from the Spanish clinic, providing temporary access to his medical card.")}
                            </h2>
                            <p>
                                {t("The doctor can thus get acquainted with the entire medical history of Mammad, learn about allergies to medicines, view the history of past illnesses and surgical interventions and make sure that the treatment that he prescribes will be safe for Mammad.")}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='help-block'>
                    <div className='help-content'>
                        <h2>
                            {t('If your doctor or medical institution that you have applied to does not have information about your health and access to your medical record, this may be for several reasons')}:
                        </h2>

                        <div className='help-item'>
                            <img src={AddCircleSVG} width={22} alt={'Add circle'} />
                            <p>
                                {t('Another organization may still use paper medical records or EHRs that cannot share data.')}
                            </p>
                        </div>

                        <div className='help-item'>
                            <img src={AddCircleSVG} width={22} alt={'Add circle'} />
                            <p>
                                {t("There may be local laws that require you to sign a consent form before your records can be shared with organizations, and this consent form is not on file.")}
                            </p>
                        </div>

                        <div className='help-item'>
                            <img src={AddCircleSVG} width={22} alt={'Add circle'} />
                            <p>
                                {t("There may be a mismatch of important information about you. For example, your name or date of birth may not be the same in two organizations.")}
                            </p>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

export const ShareComponent = withTranslation()(Proto);