import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { WithTranslation, withTranslation } from "react-i18next";

// svg
import HealthCardLogoSVG from './../../assets/svg/health-card-logo.svg';
import VideodoktorLogoSVG from './../../assets/svg/videodoktor-logo.svg';
import TelephoneSVG from './../../assets/svg/telephone.svg';
import MessageSVG from './../../assets/svg/message.svg';
import EzgilWhiteLogoSVG from './../../assets/svg/ezgil-white-logo.svg';
import WhatsappSVG from './../../assets/svg/whatsapp.svg';

interface MyProps extends WithTranslation {
}

interface MyState {
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    render() {

        const { t } = this.props;

        return (
            <div className='main-footer-wrapper'>
                <div className='main-footer'>

                    <div className='main-footer-left'>

                        <div className='logos'>
                            <Link to='/' className='logo-card'>
                                <img width='60' src={HealthCardLogoSVG} alt="Health Card" />
                                <p>Şəfa Kartım</p>
                            </Link>

                            <div className='logo-videodoctor'>
                                <Link to={{ pathname: 'https://videodoktor.az' }} target='_blank'>
                                    <img width='22' src={VideodoktorLogoSVG} alt="Health Card" />
                                    <p><span>by</span> VideoDoktor</p>
                                </Link>
                            </div>
                        </div>

                        <div className='contacs-block'>

                            <div className='call-us'>
                                <p>{t('Call us')}</p>
                                <div className='numbers-list'>
                                    <div>
                                        <img width='10' src={TelephoneSVG} alt="Call us" />
                                        <a href='tel:+994512063813'>(+994) 51-206-38-13</a>
                                    </div>
                                    <div>
                                        <img width='15' src={WhatsappSVG} alt="Call us" />
                                        <a href='tel:+994512063813'>(+994) 51-206-38-13</a>
                                    </div>
                                </div>
                            </div>

                            <div className='write-us'>
                                <p>{t('Write to us')}:</p>
                                <div className='emails-list'>
                                    <div>
                                        <img width='10' src={MessageSVG} alt="Message Us" />
                                        <a href='mailto:info@videodoktor.az'>info@videodoktor.az</a>
                                    </div>
                                    <div>
                                        <a href='mailto:dataprotection@videodoktor.az'>dataprotection@videodoktor.az</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='main-footer-right'>

                        <div className='navigation-links'>
                            <Link to='/about'>{t('About us')}</Link>
                            <Link to={{ pathname: 'https://videodoktor.az' }} target='_blank'>{t('Access to Şəfa Kartım')}</Link>
                            <Link to='/functions'>{t('Functions')}</Link>
                            <Link to='/help'>{t('Help')}</Link>
                        </div>

                        <div className='ezgil-logotype'>
                            <img width='105' src={EzgilWhiteLogoSVG} alt="Ezgil logotype" />
                            <p>EZGIL medical technologies © 2021. {t('All rights reserved')}.</p>
                        </div>

                    </div>

                    <div className='ezgil-logo-mobile'>
                        <Link to={{ pathname: 'https://ezgil.az' }} target='_blank'>
                            <img width='105' src={EzgilWhiteLogoSVG} alt="Ezgil logotype" />
                        </Link>
                        <p>EZGIL medical technologies © 2021. {t('All rights reserved')}.</p>
                    </div>

                </div>
            </div >
        )
    }
}

export const FooterComponent = withTranslation()(Proto);