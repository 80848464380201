import React from 'react';
import { Link } from 'react-router-dom';
import './Home.scss';
import { WithTranslation, withTranslation } from "react-i18next";

// svg
import HealthCardLogoSVG from './../../assets/svg/health-card-logo.svg';
import AddCircleSVG from './../../assets/svg/add-circle.svg';

interface MyProps extends WithTranslation {
}

interface MyState {
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { t } = this.props;
        const lang = this.props.i18n.language;

        return (
            <div className='home-wrapper'>

                <div className='welcome-block'>
                    <div className='main-info-block'>
                        <div className='health-icon-block'>
                            <img width='85' src={HealthCardLogoSVG} alt="Health Card" />
                            <h2>Şəfa Kartım</h2>
                        </div>
                        <div className='desc-and-button'>
                            <p>{t('Join the hundreds of patients who already use Şəfa Kartım to manage their health and treatment')}</p>
                            <Link to={{ pathname: 'https://videodoktor.az' }} target='_blank' className='blue-button'>
                                {t('Access to Şəfa Kartım')}
                            </Link>
                        </div>
                    </div>

                    <div className='apps-block'>
                        <Link to={{ pathname: 'https://apps.apple.com/ru/app/videodoktor/id1520369601' }} target='_blank'>
                            <img width={140} src={require('./../../assets/images/appstore.png').default} alt="App Store" />
                        </Link>
                        <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=az.ezgil.videodoctor' }} target='_blank'>
                            <img width={170} src={require('./../../assets/images/playmarket.png').default} alt="Google play" />
                        </Link>
                    </div>
                </div>

                <div className='features-list-main-block'>

                    <h2>{t('Medcard in a smartphone')}</h2>
                    <h3>{t('"Şəfa Kartım" gives you full access to the medcard and allows you to:')}</h3>

                    <div className='features-list-items'>
                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('make an appointment with a doctor, reschedule an appointment, and set up notifications about upcoming visits')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('make an appointment for laboratory tests by a prescription')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('get acquainted with the protocols of medical examinations, the results of laboratory and instrumental studies, and extracts from hospitals')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('independently enter data of body temperature, oxygen level, pulse, blood sugar level and blood pressure')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('get acquainted with information about your own vaccinations and the history of vaccination of children under 18 years of age')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('download medical documents and send them via email or messengers')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('independently upload medical documents: the examination report, the results of laboratory and instrumental studies, the discharge epicrisis from the hospital, the prescription, the conclusion of the medical commission')}</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('read the results of tests for coronavirus infection made after April 20')}</p>
                        </div>


                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>{t('view your prescriptions')}</p>
                        </div>

                        <div></div>

                    </div>

                </div>

                <div className='app-features-block'>
                    <div className='left'>
                        <img width='215' src={
                            lang === 'az' ?
                                require('./../../assets/images/app-features-list-az.png').default
                                : lang === 'en' ?
                                    require('./../../assets/images/app-features-list-en.png').default
                                    :
                                    require('./../../assets/images/app-features-list-ru.png').default
                        } alt="App features list" />
                    </div>
                    <div className='button-mob'>
                        <Link to={'/medical-card'} className='blue-button'>{t('Learn more')}</Link>
                    </div>
                    <div className='middle'>
                        <h2>{t('All your health information in one place')}</h2>
                        <p>
                            {t('See your medications, test results, upcoming appointments, medical bills, cost estimates, and more in one place, even if you\'ve been visited by multiple medical organizations.')}
                        </p>
                        <Link to={'/medical-card'} className='blue-button'>{t('Learn more')}</Link>
                    </div>
                    <div className='right'>
                        <img width='370' src={
                            lang === 'az' ?
                                require('./../../assets/images/blood-pressure-az.png').default
                                : lang === 'en' ?
                                    require('./../../assets/images/blood-pressure-en.png').default
                                    :
                                    require('./../../assets/images/blood-pressure-ru.png').default
                        } alt="Blood pressure" />
                    </div>
                </div>

                <div className='share-your-card-block'>
                    <div className='left'>
                        <h2>{t('Safely and securely share your medcard')}</h2>
                        <p>
                            {t('Share your medcard with anyone who needs it. Most of your doctors can already get the information they need, but if they can\'t, you can share your notes on the spot.')}
                        </p>
                        <Link to={'/share'} className='blue-button'>{t('Learn more')}</Link>
                    </div>
                    <div className='right'>
                        <img width='450' src={require('./../../assets/images/share-bg.png').default} alt="Share" />
                    </div>
                    <div className='button-mob'>
                        <Link to={'/share'} className='blue-button'>{t('Learn more')}</Link>
                    </div>
                </div>

                <div className='make-an-appointment-block'>
                    <h2 className='mob-title'>{t('Quickly make an appointment and get help')}</h2>
                    <div className='left'>
                        <img width='330' src={require('./../../assets/images/guy-with-phone.png').default} alt="Guy with phone" />
                    </div>
                    <div className='middle'>
                        <h2>{t('Quickly make an appointment and get help')}</h2>
                        <p>
                            {t('Make appointments at your convenience, complete pre-visit tasks from home, and find the nearest emergency room or emergency room when you need it.')}
                        </p>
                    </div>
                    <div className='right'>
                        <img width='180' src={
                            lang === 'az' ?
                                require('./../../assets/images/appointment-in-app-az.png').default
                                : lang === 'en' ?
                                    require('./../../assets/images/appointment-in-app-en.png').default
                                    :
                                    require('./../../assets/images/appointment-in-app-ru.png').default
                        } alt="Appointment in app" />
                    </div>
                </div>

                <div className='take-care-of-your-family-block'>
                    <div className='left'>
                        <h2>{t('Take care of your children and other family members')}</h2>
                        <p>
                            {t('Keep up to date with all your appointments and check on family members who need extra help - that\'s all from your account.')}
                        </p>
                    </div>
                    <div className='right'>
                        <img width='450' src={require('./../../assets/images/take-care.png').default} alt="Take care" />
                    </div>
                </div>

                <div className='contact-your-doctor-block'>
                    <h2 className='mob-title'>{t('Contact your doctor wherever you are')}</h2>
                    <div className='left'>
                        <img width='270' src={require('./../../assets/images/girl-with-notebook.png').default} alt="Girl with notebook" />
                    </div>
                    <div className='middle'>
                        <h2>{t('Contact your doctor wherever you are')}</h2>
                        <p>
                            {t('Send a message, get online diagnosis and treatment, talk face-to-face via video, or arrange for a follow-up visit to the doctor in person, depending on the level of care you need')}
                        </p>
                    </div>
                    <div className='right'>
                        <img width='350' src={require('./../../assets/images/old-man-making-appointment.png').default} alt="Old man making appointment" />
                    </div>
                </div>

                <div className='see-how-block'>
                    <h2>{t('See how Şəfa Kartım fits into your daily life')}</h2>
                    <p className='mob-desc'>{t('And that\'s just the tip of the iceberg. Take a look at the full list of features')}</p>
                    < div className='see-how-description' >
                        <div className='video-tutorial'></div>
                        <div className='video-tutorial-desc'>
                            <p>{t('And that\'s just the tip of the iceberg. Take a look at the full list of features') + "..."}</p>
                            <Link to={'/functions'} className='blue-button'>{t('View all functions')}</Link>
                        </div>
                    </div>
                </div>

                <div className='pre-footer'>
                    <h2>{t('Take your medcard with you wherever you go')}</h2>
                    <Link to={{ pathname: 'https://videodoktor.az' }} target="_blank" className='blue-button'>{t('Access to Şəfa Kartım')}</Link>

                    <p>{t('Do you use a mobile device or tablet?')}</p>
                    <p>{t('Find the Şəfa Kartım app on your platform')}</p>

                    <div className='app-markets-block'>
                        <Link to={{ pathname: 'https://apps.apple.com/ru/app/videodoktor/id1520369601' }} target='_blank'>
                            <img width={140} src={require('./../../assets/images/appstore.png').default} alt="App Store" />
                        </Link>
                        <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=az.ezgil.videodoctor' }} target='_blank'>
                            <img width={170} src={require('./../../assets/images/playmarket.png').default} alt="Google play" />
                        </Link>
                    </div>
                </div>

            </div >
        )
    }
}

export const HomeComponent = withTranslation()(Proto);