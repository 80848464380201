import React from 'react';
import { Link } from 'react-router-dom';
import './Help.scss';
import { WithTranslation, withTranslation } from "react-i18next";

interface MyProps extends WithTranslation {
}

interface MyState {
    tabIndex: number;
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            tabIndex: this.getTabIndexFromUrl(),
        };
    }

    getTabIndexFromUrl() {
        switch (window.location.pathname) {
            case '/help':
                return 0;
            case '/help/login':
                return 1;
            case '/help/faq':
                return 2;
            default:
                return 0;
        }
    }

    renderRegister() {

        const { t } = this.props;
        const lang = this.props.i18n.language;

        return (
            <>
                <div className='title-block'>
                    <h2>{t("Create an account in Şəfa Kartım")}</h2>
                    <p>
                        {t("You can create an account with Şəfa Kartım through the website www.shefakartim.az and www.videodoktor.az or via the VideoDoktor mobile app.")}
                    </p>
                    <Link to={{ pathname: 'https://videodoktor.az' }} target='_blank'>
                        {t("Register with Şəfa Kartım")}
                    </Link>
                    <div className='blackbox'></div>
                </div>

                <div className='info-block'>
                    <p>{t("If you do not have an account in Şəfa Kartım or do not have an activation code from your doctor or medical institution")}:</p>
                    <ul>
                        <li>
                            {t("Click 'Access to Şəfa Kartım' at the top of this page to find the Şəfa Kartım service provider, then click 'Sign up' to request an activation code.")}
                        </li>
                        <li>{t("Follow the instructions to verify your identity and create a Şəfa Kartım account.")}</li>
                    </ul>
                    <p>{t("Use your activation code to register")}:</p>
                    <ul>
                        <li>
                            {t("Look for the activation code in the summary after the visit, in an email or text message from your medical organization.")}
                        </li>
                        <li>
                            {t("Follow the instructions provided with your activation code to create an Şəfa Kartım account.")}
                        </li>
                    </ul>
                    <img src={
                        lang === 'az' ?
                            require('./../../assets/images/auth-window-az.jpg').default

                            : lang === 'en' ?
                                require('./../../assets/images/auth-window-en.jpg').default
                                :
                                require('./../../assets/images/auth-window-ru.jpg').default

                    } alt={'Auth window'} />
                </div>

                <div className='about-mobile-app-block'>
                    <h2>{t("Download the VideoDoktor mobile app to access Şəfa Kartım on your smartphone")}</h2>
                    <h3>
                        {t("You will be able to view, share, add, or correct your medical information through the VideoDoktor mobile app in a web browser, smartphone, or tablet.")}
                    </h3>
                    <p>
                        {t("Download the VideoDoktor app with the built-in Şəfa Kartım app to your mobile device from the Apple App Store or Google Play Store by these links")}:
                    </p>
                    <div className='download-links'>
                        <Link to={{ pathname: 'https://apps.apple.com/ru/app/videodoktor/id1520369601' }} target='_blank'>
                            <img width={140} src={require('./../../assets/images/appstore.png').default} alt="App Store" />
                        </Link>
                        <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=az.ezgil.videodoctor' }} target='_blank'>
                            <img width={170} src={require('./../../assets/images/playmarket.png').default} alt="Google play" />
                        </Link>
                    </div>
                    <p>
                        {t("Please note that some medical organizations have their own version of the Şəfa Kartım app. If you see that your application looks unique, download this version instead.")}
                    </p>
                </div>
            </>
        )
    }

    renderLogin() {
        const { t } = this.props;
        const lang = this.props.i18n.language;

        return (
            <>
                <div className='title-block'>
                    <h2>{t("I forgot my username or password Şəfa Kartım")}</h2>
                    <p>{t("Password Reset and Account management")}</p>
                    <div className='blackbox'></div>
                </div>

                <div className='info-block'>
                    <ul className='login-list'>
                        <li>{t("On the login screen of Şəfa Kartım, click 'Forgot your password?'")}</li>
                        <li>
                            {t("You will be asked to enter your username or Email address to verify your identity so that you can recover your username or password.")}
                        </li>
                        <li>
                            {t("If you need help restoring your account, please call the help desk of Şəfa Kartım listed on the login page or in the 'Frequently asked questions' section.")}
                        </li>
                    </ul>
                    <img src={
                        lang === 'az' ?
                            require('./../../assets/images/forgot-password-az.jpg').default

                            : lang === 'en' ?
                                require('./../../assets/images/forgot-password-en.jpg').default
                                :
                                require('./../../assets/images/forgot-password-ru.jpg').default

                    } alt={'Auth window'} />
                </div>
            </>
        )
    }

    renderFaq() {
        const { t } = this.props;
        return (
            <>
                <div className='title-block-faq'>
                    <h2>{t("The information about my health in Şəfa Kartım is not correct. What should I do?")}</h2>
                    <p>
                        {t("The information in Şəfa Kartım comes directly from your health care providers. Ask your doctor to correct any inaccurate information on your next visit to the clinic. You can also contact the medical records department of your medical organization to request a correction of your card.")}
                    </p>
                    <h2>{t("Is Şəfa Kartım safe?")}</h2>
                    <p>
                        {t("Your healthcare providers take great care to ensure that your medical information remains confidential and secure. Access to information is controlled by secure activation codes, personal usernames, and passwords. Unlike regular email, all Şəfa Kartım messages are executed when you securely log in to Şəfa Kartım.")}
                    </p>
                </div>
            </>
        )
    }

    renderPage() {
        const { tabIndex } = this.state;

        switch (tabIndex) {
            case 0:
                return this.renderRegister();
            case 1:
                return this.renderLogin();
            case 2:
                return this.renderFaq();
            default:
                return this.renderRegister();
        }
    }

    render() {
        const { tabIndex } = this.state;
        const { t } = this.props;

        return (
            <div className='help-wrapper'>

                <div className='welcome-block'>
                    <h2>{t("Need help?")}</h2>
                    <p>{t("Here are some general questions and answers about Şəfa Kartım")}</p>
                </div>

                <div className='features-block'>
                    <Link to={'/help'}
                        className={tabIndex === 0 ? 'feature selected' : 'feature'}
                        onClick={() => this.setState({ tabIndex: 0 })}
                    >
                        <img width={55} src={require('./../../assets/images/register-icon.png').default} alt={'Register icon'} />
                        <p>{t("Registration")}</p>
                    </Link>

                    <Link to={'/help/login'}
                        className={tabIndex === 1 ? 'feature selected' : 'feature'}
                        onClick={() => this.setState({ tabIndex: 1 })}
                    >
                        <img width={55} src={require('./../../assets/images/login-icon.png').default} alt={'Login icon'} />
                        <p>{t("Log in to the system")}</p>
                    </Link>

                    <Link to={'/help/faq'}
                        className={tabIndex === 2 ? 'feature selected' : 'feature'}
                        onClick={() => this.setState({ tabIndex: 2 })}
                    >
                        <img width={55} src={require('./../../assets/images/question-icon.png').default} alt={'Question icon'} />
                        <p>{t("Frequently Asked Questions")}</p>
                    </Link>
                </div>

                <div className='auth-help'>
                    {this.renderPage()}
                </div>

            </div >
        )
    }
}

export const HelpComponent = withTranslation()(Proto);