import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { WithTranslation, withTranslation } from "react-i18next";
import i18n from "i18next";

// svg
import HealthCardLogoSVG from './../../assets/svg/health-card-logo.svg';
import VideodoktorLogoSVG from './../../assets/svg/videodoktor-logo.svg';
import SandwichMenuSVG from './../../assets/svg/sandwich-menu.svg';

interface MyProps extends WithTranslation {
}

interface MyState {
    showSandwich: boolean;
    sellectedLang: string;
}

class Proto extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            showSandwich: false,
            sellectedLang: i18n.language || 'az',
        };
    }

    changeLang(lang: string) {
        i18n.changeLanguage(lang);
        localStorage.setItem('shefa.kartim.lang', lang);
        this.setState({ sellectedLang: lang })
    }

    render() {
        const { showSandwich, sellectedLang } = this.state;
        const { t } = this.props;

        let header_classname = "main-header ";
        if (showSandwich) {
            header_classname += "opened";
        }

        return (
            <div className='main-header-wrapper'>

                <div className='header-wrapp'>


                    <div className={header_classname}>

                        <div className='left-block'>
                            <div className='left-sub-1'>
                                <Link onClick={() => this.setState({ showSandwich: false })} to='/'>
                                    <img width='35' src={HealthCardLogoSVG} alt='Health Card Logo' />
                                    <p>Şəfa Kartım</p>
                                </Link>
                            </div>
                            <div className='separator'></div>
                            <div className='left-sub-2'>
                                <Link onClick={() => this.setState({ showSandwich: false })} to={{ pathname: 'https://videodoctor.az' }} target='_blank'>
                                    <img width='27' src={VideodoktorLogoSVG} alt='Videodoktor Logo' />
                                    <p><span>by</span>VideoDoktor</p>
                                </Link>
                            </div>
                        </div>

                        <Link to='/' className='sandwich-link' onClick={() => this.setState({ showSandwich: !this.state.showSandwich })}>
                            <img width='27' src={SandwichMenuSVG} alt='Sandwich menu' />
                        </Link>

                        <div className='right-block'>
                            <div className='lang-block-home'>
                                <p className={sellectedLang === 'az' ? 'selectedLang' : ''} onClick={() => this.changeLang('az')}>Az</p>
                                <p className={sellectedLang === 'ru' ? 'selectedLang' : ''} onClick={() => this.changeLang('ru')}>Ru</p>
                                <p className={sellectedLang === 'en' ? 'selectedLang' : ''} onClick={() => this.changeLang('en')}>En</p>
                            </div>
                            <div className='help-links'>
                                <Link to='/help'>{t('Help')}</Link>
                                <Link to='/functions'>{t('Functions')}</Link>
                            </div>
                            <div className='white-button-wrapper'>
                                <Link className='white-button' to={{ pathname: 'https://videodoctor.az' }} target='_blank' >
                                    {t('Access to Şəfa Kartım')}
                                </Link>
                            </div>
                        </div>

                        {
                            showSandwich &&
                            <div className='right-block-mobile'>
                                <div className='lang'>
                                    <Link onClick={() => {
                                        this.changeLang('az');
                                        this.setState({ showSandwich: false })
                                    }} to='/'>Az</Link>
                                    <Link onClick={() => {
                                        this.changeLang('ru');
                                        this.setState({ showSandwich: false })
                                    }} to='/'>Ru</Link>
                                    <Link onClick={() => {
                                        this.changeLang('en');
                                        this.setState({ showSandwich: false })
                                    }} to='/'>En</Link>
                                </div>
                                <div className='links'>
                                    <Link onClick={() => this.setState({ showSandwich: false })} to='/help'>{t('Help')}</Link>
                                    <Link onClick={() => this.setState({ showSandwich: false })} to='/functions'>{t('Functions')}</Link>
                                    <Link onClick={() => this.setState({ showSandwich: false })} to={{ pathname: 'https://videodoctor.az' }} target='_blank'>{t('Access to Şəfa Kartım')}</Link>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div >
        )
    }
}

export const HeaderComponent = withTranslation()(Proto);