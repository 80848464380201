import React from 'react';
import { Switch, Route } from "react-router-dom";
import { HomeComponent } from './components/Home/Home';
import { HeaderComponent } from './components/Header/Header';
import { FooterComponent } from './components/Footer/Footer';
import { MedicalCardComponent } from './components/MedicalCard/MedicalCard';
import { ShareComponent } from './components/Share/Share';
import { AboutComponent } from './components/About/About';
import { HelpComponent } from './components/Help/Help';
import { FunctionsComponent } from './components/Functions/Functions';
import { WithTranslation, withTranslation } from 'react-i18next';
import './i18n';

interface MyProps extends WithTranslation {
}

interface MyState {
}

class App extends React.Component<MyProps, MyState> {

  constructor(props: MyProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <HeaderComponent />

        <Switch>
          <Route exact path='/'>
            <HomeComponent />
          </Route>
        </Switch >

        <Switch>
          <Route path='/medical-card'>
            <MedicalCardComponent />
          </Route>
        </Switch >

        <Switch>
          <Route path='/share'>
            <ShareComponent />
          </Route>
        </Switch >

        <Switch>
          <Route path='/about'>
            <AboutComponent />
          </Route>
        </Switch >

        <Switch>
          <Route path='/help'>
            <HelpComponent />
          </Route>
        </Switch >

        <Switch>
          <Route path='/functions'>
            <FunctionsComponent />
          </Route>
        </Switch >

        <FooterComponent />
      </React.Fragment >
    )
  }

}

export default withTranslation()(App);